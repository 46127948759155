import * as React from "react"
import Layout from '../components/layout';

export default () => {
  return (
    <Layout>
      <h1>minata</h1>
    </Layout>
  )
}
